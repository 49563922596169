import Piece from './Piece';
import seedrandom, { PRNG } from 'seedrandom';

export default class Puzzle {
    
    pieces: Piece[];
    pieceRenderOrder: Piece[];
    
    image: HTMLImageElement;
    
    width: number;
    height: number;
    pieceWidth: number;
    pieceHeight: number;
    
    randomSeed: string;
    random: PRNG;
    
    constructor(randomSeed: string, image: HTMLImageElement, width: number, height: number, pieceWidth: number, pieceHeight: number) {
        
        this.pieces = [];
        this.pieceRenderOrder = [];
        this.image = image;
        
        this.width = width;
        this.height = height;
        this.pieceWidth = pieceWidth;
        this.pieceHeight = pieceHeight;
        
        this.randomSeed = randomSeed;
        this.random = seedrandom(randomSeed);
        
        for (let row = 0; row < height; row++) {
            for (let col = 0; col < width; col++) {
                
                const posRandom = Math.floor(Math.random()*6);
                let px = 0;
                let py = 0;
                switch (posRandom) {
                    case 0: { // left side
                        px = Math.round(5 + Math.random()*(window.innerWidth - pieceWidth)*0.15);
                        py = Math.round(30 + Math.random()*(window.innerHeight - pieceHeight));
                        break;
                    }
                    case 1: { // right side
                        px = Math.round(window.innerWidth - (60 + Math.random()*(window.innerWidth - pieceWidth)*0.15));
                        py = Math.round(30 + Math.random()*(window.innerHeight - pieceHeight));
                        break;
                    }
                    case 2:
                    case 3: { // top side
                        px = Math.round(30 + Math.random()*(window.innerWidth-pieceWidth));
                        py = Math.round(30 + Math.random()*(window.innerHeight - pieceHeight)*0.15);
                        break;
                    }
                    case 4:
                    case 5: { // bottom side
                        px = Math.round(30 + Math.random()*(window.innerWidth-pieceWidth));
                        py = Math.round(window.innerHeight - (60 + Math.random()*(window.innerHeight - pieceHeight)*0.15));
                        break;
                    }
                }
                
                const piece = new Piece(
                    this,
                    px, py,
                    col, row,
                    pieceWidth, pieceHeight,
                    this.random() < 0.5, this.random() < 0.5
                );
                this.pieceRenderOrder.push(piece);
                this.pieces.push(piece);
            }
        }
        
    }
    
    autoSolve() {
        
        let n = 0;
        for (const piece of this.pieces) {
            piece.animateTo(
                Math.floor(window.innerWidth/2 - (this.width*this.pieceWidth)/2) + piece.puzzleX*piece.width,
                Math.floor(window.innerHeight/2 - (this.height*this.pieceHeight)/2) + piece.puzzleY*piece.height,
                (n++)*50
            );
            piece.checkNear();
        }
        
    }
    
    resize(width: number, height: number) {
        for (const piece of this.pieces) {
            
            let px = piece.x;
            let py = piece.y;
            
            if (piece.x > width-20) {
                px = width-25;
            }
            if (piece.y > height-20) {
                py = height-25;
            }
            
            if (px != piece.x || py != piece.y) {
                piece.moveAll(px, py);
            }
            
        }
    }
    
    render(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, currentPiece: Piece | null) {
        
        for (const piece of this.pieceRenderOrder) {
            piece.render(canvas, ctx, currentPiece);
        }
        
    }
    
    getPiece(x: number, y: number) {
        return this.pieces[y*this.width + x];
    }
    
    serializeState() {
        
        const out: SerializedGame = {
            pieces: [],
            pieceWidth: this.pieceWidth,
            pieceHeight: this.pieceHeight,
            width: this.width,
            height: this.height
        };
        
        for (const piece of this.pieces) {
            out.pieces.push(piece.serializeState());
        }
        
        return out;
        
    }
    
    static fromState(image: HTMLImageElement, randomSeed: string, data: SerializedGame) {
        
        const puz = new Puzzle(randomSeed, image, data.width, data.height, data.pieceWidth, data.pieceHeight);
        
        let n = 0;
        for (const pieceRaw of data.pieces) {
            puz.pieces[n++].restoreState(pieceRaw);
        }
        
        return puz;
        
    }
    
}

export interface SerializedGame {
    pieces: number[][],
    pieceWidth: number,
    pieceHeight: number,
    width: number,
    height: number
}