import { h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { Howler, Howl } from 'howler';
import Piece from './game/Piece';
import Puzzle, { SerializedGame } from './game/Puzzle';
import RemixIcon from './util/RemixIcon';

export const SOUNDS_PICK = [ // @ts-ignore
    new Howl({ src: new URL('../assets/sfx/pickup.ogg', import.meta.url).href, html5: false }), // @ts-ignore
    new Howl({ src: new URL('../assets/sfx/pickup2.ogg', import.meta.url).href, html5: false }), // @ts-ignore
    new Howl({ src: new URL('../assets/sfx/pickup3.ogg', import.meta.url).href, html5: false }), // @ts-ignore
];

export const SOUNDS_PLACE = [ // @ts-ignore
    new Howl({ src: new URL('../assets/sfx/place2.ogg', import.meta.url).href, html5: false })
];

export const SOUNDS_SNAP = [ // @ts-ignore
    new Howl({ src: new URL('../assets/sfx/snap.ogg', import.meta.url).href, html5: false })
];


const KEY_PUZZLE_STATES = `__palava_states`;

async function savePuzzleState(puzzleId: string, puzzle: Puzzle) {
    
    if (!window.localStorage) {
        throw new Error(`Local storage not available`);
    }
    
    let state: {[key: string]: SerializedGame} = {};
    try {
        const rawState = window.localStorage.getItem(KEY_PUZZLE_STATES);
        if (rawState) {
            state = JSON.parse(rawState);
        }
    } catch (err) {}
    
    state[puzzleId] = puzzle.serializeState();
    window.localStorage.setItem(KEY_PUZZLE_STATES, JSON.stringify(state));
    
}

async function restorePuzzleState(puzzleId: string) {
    
    if (!window.localStorage) {
        throw new Error(`Local storage not available`);
    }
    
    let state: {[key: string]: SerializedGame} = {};
    try {
        const rawState = window.localStorage.getItem(KEY_PUZZLE_STATES);
        if (rawState) {
            state = JSON.parse(rawState);
        }
        if (!state) state = {};
    } catch (err) {}
    
    if (!state[puzzleId]) {
        return null;
    }
    
    return state[puzzleId];
    
}

export function getPieceAt(screenX: number, screenY: number) {
    
    if (!puzzle) {
        return null;
    }
    
    let i = puzzle.pieceRenderOrder.length;
    while (i--) {
        const piece = puzzle.pieceRenderOrder[i];
        if (screenX >= piece.x && screenX < piece.x + piece.width
            && screenY >= piece.y && screenY < piece.y + piece.height) {
            return piece;
        }
    }
    
    return null;
    
}

let draggingPiece: Piece | null = null;
let draggingPos: number[] | null = null;

function onMouseDown(e: MouseEvent) {
    
    if (!puzzle) {
        return;
    }
    
    const mousePiece = getPieceAt(e.clientX, e.clientY);
    if (!mousePiece) {
        return;
    }
    
    for (const connected of mousePiece.getConnected().values()) {
        const pieceRenderIndex = puzzle.pieceRenderOrder.indexOf(connected);
        puzzle.pieceRenderOrder.push(puzzle.pieceRenderOrder.splice(pieceRenderIndex, 1)[0]);
    }
    
    const sound = SOUNDS_PICK[Math.floor(Math.random()*SOUNDS_PICK.length)];
    const soundId = sound.play();
    sound.rate(0.9 + Math.random()*0.2, soundId);
    
    draggingPiece = mousePiece;
    draggingPos = [e.clientX - mousePiece.x, e.clientY - mousePiece.y];
    
}

function onMouseUp(e: MouseEvent) {
    
    if (draggingPiece) {
        
        const didSnap = draggingPiece.checkNear();
        
        if (didSnap) {
            const sound = SOUNDS_SNAP[Math.floor(Math.random()*SOUNDS_SNAP.length)];
            const soundId = sound.play();
            sound.rate(0.9 + Math.random()*0.2, soundId);
        } else {
            const sound = SOUNDS_PLACE[Math.floor(Math.random()*SOUNDS_PLACE.length)];
            const soundId = sound.play();
            sound.rate(0.9 + Math.random()*0.2, soundId);
        }
    }
    
    draggingPiece = null;
    
}

function onMouseMove(e: MouseEvent) {
    if (draggingPiece && draggingPos) {
        draggingPiece.moveAll(e.clientX - draggingPos[0], e.clientY - draggingPos[1]);
    }
}

function loadImage(src: string | URL) {
    return new Promise<HTMLImageElement>((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            resolve(img);
        }
        img.onerror = err => {
            reject(err);
        }
        // @ts-ignore
        img.src = src;
    });
}

export enum GameState {
    NONE,
    LOADING,
    PLAYING
}

let puzzle: Puzzle | null = null;

let gameState = GameState.NONE;

let fpsCounter = 0;
let FPS = 0;

setInterval(() => {
    FPS = fpsCounter;
    fpsCounter = 0;
}, 1000);

setInterval(() => {
    if (puzzle != null && gameState == GameState.PLAYING) {
        savePuzzleState(puzzle.randomSeed, puzzle);
    }
}, 1000*10);

window.addEventListener('beforeunload', e => {
    if (puzzle != null && gameState == GameState.PLAYING) {
        savePuzzleState(puzzle.randomSeed, puzzle);
    }
});

function render(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) {
    
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.font = '16px Arial';
    ctx.textBaseline = 'top';
    
    ctx.strokeStyle = '#000000';
    ctx.lineWidth = 1;
    
    if (gameState == GameState.NONE) {
        
    } else if (gameState == GameState.LOADING) {
        
        ctx.fillText(`Loading...`, canvas.width/2, canvas.height/2);
        
    } else if (gameState == GameState.PLAYING && puzzle) {
        
        puzzle.render(canvas, ctx, draggingPiece);
        
    }
    
    ctx.fillText(`${FPS} fps`, 5, 45);
    fpsCounter++;
    
}

export function jigsawPieceAsImage(width: number, height: number, sides: (0 | 1 | 2)[]): Promise<string> {
    
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) throw new Error('Unable to use ctx');
    
    canvas.width = width + 2;
    canvas.height = height + 2;
    
    ctx.strokeStyle = '#000000';
    ctx.fillStyle = '#ffffff';
    ctx.lineWidth = 1;
    
    ctx.beginPath();
    jigsawPiece(ctx, canvas.width/2 - width/2, canvas.width/2 - height/2, width, height, sides);
    ctx.fill();
    ctx.stroke();
    
    return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
            if (!blob) {
                reject(new Error('Blob is null?!'));
                return;
            }
            resolve(URL.createObjectURL(blob));
        }, 'image/png')
    });
    
}

export function jigsawPiece(ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, sides: (0 | 1 | 2)[]) {
    
    const earSize = Math.min(width*0.2, height*0.2);
    
    ctx.moveTo(x, y);
    jigsawEdge(ctx, x, y, 0, width, earSize, sides[0]);
    jigsawEdge(ctx, x + width, y, Math.PI*0.5, height, earSize, sides[1]);
    jigsawEdge(ctx, x + width, y + height, Math.PI, width, earSize, sides[2]);
    jigsawEdge(ctx, x, y + height, -Math.PI*0.5, height, earSize, sides[3]);
    
}

export function jigsawEdge(ctx: CanvasRenderingContext2D, x: number, y: number, angle: number, length: number, earSize: number, lineMode: 0 | 1 | 2) {
    
    if (lineMode == 0) {
        ctx.lineTo(x + Math.cos(angle)*length, y + Math.sin(angle)*length);
        return;
    }
    
    const cosAngle = Math.cos(angle);
    const sinAngle = Math.sin(angle);
    
    ctx.lineTo(x, y);
    ctx.lineTo(x + cosAngle*(length/2 - earSize/2), y + sinAngle*(length/2 - earSize/2));
    
    if (lineMode == 2) {
        ctx.bezierCurveTo(
            x + cosAngle*(length/2) + Math.cos(Math.PI + angle-Math.PI/2 + Math.PI*0.25)*earSize*2.2, y + sinAngle*(length/2) + Math.sin(Math.PI + angle-Math.PI/2 + Math.PI*0.2)*earSize*2.2,
            x + cosAngle*(length/2) + Math.cos(Math.PI + angle-Math.PI/2 - Math.PI*0.25)*earSize*2.2, y + sinAngle*(length/2) + Math.sin(Math.PI + angle-Math.PI/2 - Math.PI*0.2)*earSize*2.2,
            x + cosAngle*(length/2 + earSize/2), y + sinAngle*(length/2 + earSize/2)
        )
    } else {
        ctx.bezierCurveTo(
            x + cosAngle*(length/2) + Math.cos(angle-Math.PI/2 - Math.PI*0.25)*earSize*2.2, y + sinAngle*(length/2) + Math.sin(angle-Math.PI/2 - Math.PI*0.2)*earSize*2.2,
            x + cosAngle*(length/2) + Math.cos(angle-Math.PI/2 + Math.PI*0.25)*earSize*2.2, y + sinAngle*(length/2) + Math.sin(angle-Math.PI/2 + Math.PI*0.2)*earSize*2.2,
            x + cosAngle*(length/2 + earSize/2), y + sinAngle*(length/2 + earSize/2)
        )
    }
    
    ctx.lineTo(x + cosAngle*(length/2 + earSize/2), y + sinAngle*(length/2 + earSize/2));
    ctx.lineTo(x + cosAngle*(length), y + sinAngle*(length));
    
}

export interface PalavaSettings {
    pieceSize: number;
    useSounds: boolean;
}

const PALAVA_SETTINGS: PalavaSettings = {
    pieceSize: 70,
    useSounds: true
};

export interface ExploreData {
    title: string;
    size: [number, number];
    image: string;
    author: string;
}

function PuzzleExplorer(props: { onHide: () => any, onChange: (p: ExploreData) => any }) {
    
    const [exploreData, setExploreData] = useState<ExploreData[] | null>(null);
    const [errorText, setError] = useState<string | null>(null);
    const rawPuzzlesData = JSON.parse(localStorage.getItem(KEY_PUZZLE_STATES) ?? '{}');
    
    useEffect(() => {
        
        fetch(`puzzles.json?${Date.now()}`)
            .then(x => x.json())
            .then(rawData => {
                const puzzlesData: ExploreData[] = rawData;
                setExploreData(puzzlesData);
            })
            .catch(err => {
                setError(`${err}`);
            })
        
    }, []);
    
    return <div className='p-explorer'>
        <div className='p-logo'>
            {/* @ts-ignore */}
            <img src={new URL('../assets/logo.png', import.meta.url)}/>
            Palava
        </div>
        <div className='p-explorer-content'>
            
            <p>
                <strong>Tervetuloa Palavaan, ilmaiseen palapelikokoelmaan.</strong> {' '}
                Täältä löydät silloin tällöin päivittyvän palapelien joukon
                ilman mainoksia.
            </p>
            
            {exploreData
                ? <div>
                    {exploreData.map((pz, i) =>
                        <div className='p-explorer-puzzle' key={i}>
                            <div>
                                <img
                                    src={pz.image}
                                    />
                            </div>
                            <div>
                                <h4>{pz.title}</h4>
                                <p>&copy; {pz.author}</p>
                                
                                {[3, 4, 5, 8, 10].map(factor =>
                                    <button key={factor}
                                        className='p-button'
                                        style={{ marginRight: '4px' }}
                                        onClick={e => {
                                            gameState = GameState.LOADING;
                                            loadImage(pz.image)
                                                .then(img => {
                                                    puzzle = new Puzzle(pz.title, img, factor*pz.size[0], factor*pz.size[1], 60, 60);
                                                    gameState = GameState.PLAYING;
                                                    props.onHide();
                                                })
                                                .catch(err => {
                                                    console.error(err);
                                                    alert(`Error: ${err}`);
                                                })
                                        }}
                                        >
                                        {pz.size[0]*factor}&times;{pz.size[1]*factor}
                                    </button>
                                )}
                                {rawPuzzlesData[pz.title]
                                    ? <div key={`continue_${pz.title}`}>
                                        <button
                                            className='p-button continue'
                                            style={{ marginRight: '4px', marginTop: '4px' }}
                                            onClick={e => {
                                                gameState = GameState.LOADING;
                                                loadImage(pz.image)
                                                    .then(async img => {
                                                        const puzzleData = await restorePuzzleState(pz.title);
                                                        if (!puzzleData) throw new Error(`Unable to restore`);
                                                        puzzle = Puzzle.fromState(img, pz.title, puzzleData)
                                                        gameState = GameState.PLAYING;
                                                        props.onHide();
                                                    })
                                                    .catch(err => {
                                                        console.error(err);
                                                        alert(`Error: ${err}`);
                                                    })
                                            }}
                                            >
                                            &raquo; Jatka aiempaa peliä
                                        </button>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    )}
                </div>
                : <p>Ladataan...</p>}
            
        </div>
    </div>;
    
}

const PUZZLE_SIZE_OPTIONS = [40, 55, 70, 85, 100];

function PuzzleSettings(props: { settings: PalavaSettings, onHide: () => any, onChange: (p: any) => any }) {
    
    const [puzzleSizeImages, setPuzzleSizeImages] = useState<string[] | null>(null);
    const [editedSettings, setEditedSettings] = useState<PalavaSettings>(props.settings);
    
    useEffect(() => {
        (async () => {
            
            const sizeImages = [];
            
            for (const pieceSize of PUZZLE_SIZE_OPTIONS) {
                sizeImages.push(await jigsawPieceAsImage(pieceSize, pieceSize, [2, 2, 2, 2]));
            }
            
            setPuzzleSizeImages(sizeImages);
            
        })().catch(err => {
            console.error(err);
        })
    }, []);
    
    return <div className='p-explorer'>
        <h2 style={{ textAlign: 'center' }}>
            <RemixIcon icon='settings-4-fill'/> Asetukset
        </h2>
        <div className='p-explorer-content'>
            
            {puzzleSizeImages
                ? <div>
                    
                    <h4>Palapelin palojen koko</h4>
                    <div className='p-size-selection' style={{ marginTop: '-24px' }}>
                        {puzzleSizeImages.map((imgBlob, i) => 
                            <div className='p-size-selection-option' data-selected={`${editedSettings.pieceSize == PUZZLE_SIZE_OPTIONS[i]}`}>
                                <img src={imgBlob} key={imgBlob}/>
                                <div>{PUZZLE_SIZE_OPTIONS[i]}px</div>
                            </div>
                        )}
                    </div>
                    
                </div>
                : <div>Hetkinen...</div>}
            
        </div>
    </div>;
    
}

export default function Palava(props: {}) {
    
    const [puzzleExplorerOpen, setPuzzleExplorerOpen] = useState<boolean>(true);
    const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
    const [currentPuzzle, setCurrentPuzzle] = useState<string | null>(null);
    
    const [isSoundMuted, setSoundMuted] = useState<boolean>(false);
    const [puzzleSettings, setPuzzleSettings] = useState<PalavaSettings>(PALAVA_SETTINGS);
    
    const refCanvas = useRef<HTMLCanvasElement>(null);
    const [canvasSize, setCanvasSize] = useState<number[]>([window.innerWidth, window.innerHeight]);
    
    useEffect(() => {
        if (isSoundMuted) {
            Howler.volume(0);
        } else {
            Howler.volume(1);
        }
    }, [isSoundMuted]);
    
    useEffect(() => {
        
        function onResize() {
            setCanvasSize([window.innerWidth, window.innerHeight]);
            puzzle?.resize(window.innerWidth, window.innerHeight);
        }
        
        window.addEventListener('resize', onResize);
        
        return () => {
            window.removeEventListener('resize', onResize);
        }
        
    }, []);
    
    useEffect(() => {
        
        if (!refCanvas.current) {
            return;
        }
        
        const canvas = refCanvas.current;
        const ctx = canvas.getContext('2d');
        let lastFrame: number | null = null;
        
        if (!ctx) {
            return;
        }
        
        function _render() {
            if (!ctx) return;
            render(canvas, ctx);
            lastFrame = window.requestAnimationFrame(_render);
        }
        
        lastFrame = window.requestAnimationFrame(_render);
        
        return () => {
            if (lastFrame) {
                window.cancelAnimationFrame(lastFrame);
            }
        }
        
    });
    
    return <div className='p-main'>
        <div className='p-top'>
            <div className='p-logo'>
                {/* @ts-ignore */}
                <img src={new URL('../assets/logo.png', import.meta.url)}/>
                Palava
            </div>
            <div className='p-buttons'>
                <button className='p-top-button'
                    onClick={e => {
                        e.preventDefault();
                        setSoundMuted(m => !m);
                    }}
                    ><RemixIcon icon={isSoundMuted ? 'volume-mute-fill' : 'volume-down-fill'}/></button>
                <button className='p-top-button'
                    onClick={e => {
                        e.preventDefault();
                        if (!puzzle) return;
                        const didConfirm = confirm('Rakenna palapeli loppuun automaattisesti?');
                        if (didConfirm) {
                            puzzle?.autoSolve();
                        }
                    }}
                    ><RemixIcon icon='speed-fill'/></button>
                <button className='p-top-button'
                    onClick={e => {
                        e.preventDefault();
                        setPuzzleExplorerOpen(o => !o);
                    }}
                    ><RemixIcon icon='layout-grid-fill'/></button>
                {/*
                <button className='p-top-button'
                    onClick={e => {
                        e.preventDefault();
                        
                    }}
                    ><RemixIcon icon='settings-4-fill'/></button>
                <button className='p-top-button'
                    onClick={e => {
                        e.preventDefault();
                        
                    }}
                    ><RemixIcon icon='lock-2-fill'/></button>
                */}
            </div>
        </div>
        <div className='p-canvas'>
            <canvas
                id='p-game'
                width={canvasSize[0]}
                height={canvasSize[1]}
                ref={refCanvas}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onMouseMove={onMouseMove}
                />
        </div>
        <div className='p-reference-image'>
            {currentPuzzle
                ? <img
                    src={currentPuzzle}
                    />
                : ''}
        </div>
        {puzzleExplorerOpen
            ? <PuzzleExplorer
                onChange={puzzle => {
                    
                }}
                onHide={() => {
                    setPuzzleExplorerOpen(false);
                }}
                />
            : ''}
        {settingsOpen
            ? <PuzzleSettings
                settings={puzzleSettings}
                onChange={settings => {
                    
                }}
                onHide={() => {
                    setSettingsOpen(false);
                }}
                />
            : ''}
    </div>;
    
}