import { h } from 'preact';

export default function RemixIcon(props: {
    icon: string,
    className?: string
}) {
    let iconId = props.icon;
    if (!iconId.startsWith('ri-')) iconId = `ri-${iconId}`;
    return <i className={[iconId, ...(props.className ?? '').trim().split(' ').filter(n => n != '')].join(' ')}
        />;
}
