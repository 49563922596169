import { SOUNDS_PLACE, getPieceAt, jigsawPiece } from '../Palava';
import Puzzle from './Puzzle';

export default 

class Piece {
    
    x: number;
    y: number;
    
    targetX: number;
    targetY: number;
    fromX: number;
    fromY: number;
    isAnimating: boolean;
    timeStartAnimating: number;
    
    puzzle: Puzzle;
    puzzleX: number;
    puzzleY: number;
    
    width: number;
    height: number;
    
    sideRight: boolean;
    sideBottom: boolean;
    
    pieceRight: Piece | null;
    pieceLeft: Piece | null;
    
    pieceTop: Piece | null;
    pieceBottom: Piece | null;
    
    pieceCanvas: HTMLCanvasElement;
    pieceCtx: CanvasRenderingContext2D;
   
    
    serializeState() {
        const { x, y, pieceRight, pieceLeft, pieceTop, pieceBottom } = this;
        return [x, y, pieceRight ? 1 : 0, pieceLeft ? 1 : 0, pieceTop ? 1 : 0, pieceBottom ? 1 : 0];
    }
    
    restoreState(data: number[]) {
        this.x = data[0];
        this.y = data[1];
        this.pieceRight = data[2] == 1 ? this.puzzle.getPiece(this.puzzleX+1, this.puzzleY) : null;
        this.pieceLeft = data[3] == 1 ? this.puzzle.getPiece(this.puzzleX-1, this.puzzleY) : null;
        this.pieceTop = data[4] == 1 ? this.puzzle.getPiece(this.puzzleX, this.puzzleY-1) : null;
        this.pieceBottom = data[5] == 1 ? this.puzzle.getPiece(this.puzzleX, this.puzzleY+1) : null;
    }
    
    constructor(puzzle: Puzzle, x: number, y: number, puzzleX: number, puzzleY: number, width: number, height: number, sideRight: boolean, sideBottom: boolean) {
        
        this.puzzle = puzzle;
        this.x = x;
        this.y = y;
        this.puzzleX = puzzleX;
        this.puzzleY = puzzleY;
        this.width = width;
        this.height = height;
        
        this.sideRight = sideRight;
        this.sideBottom = sideBottom; 
        
        this.pieceLeft = null;
        this.pieceRight = null;
        this.pieceTop = null;
        this.pieceBottom = null;
        
        this.pieceCanvas = document.createElement('canvas');
        this.pieceCanvas.width = this.width*2;
        this.pieceCanvas.height = this.height*2;
        
        this.targetX = 0;
        this.targetY = 0;
        this.fromX = 0;
        this.fromY = 0;
        this.isAnimating = false;
        this.timeStartAnimating = Date.now();
        
        const _ctx = this.pieceCanvas.getContext('2d');
        if (!_ctx) throw new Error(`Unable to initialize context for piece canvas`);
        this.pieceCtx = _ctx;
        
        this.preRender();
        
    }
    
    getConnected(baseSet: Set<Piece> = new Set<Piece>()) {
        
        if (baseSet.has(this)) {
            return baseSet;
        }
        
        baseSet.add(this);
        
        if (this.pieceRight) {
            this.pieceRight.getConnected(baseSet);
        }
        
        if (this.pieceBottom) {
            this.pieceBottom.getConnected(baseSet);
        }
        
        if (this.pieceTop) {
            this.pieceTop.getConnected(baseSet);
        }
        
        if (this.pieceLeft) {
            this.pieceLeft.getConnected(baseSet);
        }
        
        return baseSet;
        
    }
    
    moveAll(toX: number, toY: number) {
        
        const connected = this.getConnected();
        const diffX = toX - this.x;
        const diffY = toY - this.y;
        
        for (const piece of connected.values()) {
            piece.x += diffX;
            piece.y += diffY;
        }
        
    }
    
    animateTo(x: number, y: number, timeOffset: number) {
        this.isAnimating = true;
        this.targetX = x;
        this.targetY = y - 10;
        this.fromX = this.x;
        this.fromY = this.y;
        this.timeStartAnimating = Date.now() + timeOffset;
    }
    
    checkNear(checkedRecursive: Set<Piece> = new Set()): boolean {
        
        const pieceLeft = getPieceAt(this.x - 30, this.y + this.height/2);
        const pieceRight = getPieceAt(this.x + this.width + 30, this.y + this.height/2);
        const pieceUp = getPieceAt(this.x + this.width/2, this.y - 30);
        const pieceDown = getPieceAt(this.x + this.width/2, this.y + this.height + 30);
        
        let didSnap = false;
        
        if (pieceLeft && pieceLeft.puzzleX == this.puzzleX-1 && pieceLeft.puzzleY == this.puzzleY && !pieceLeft.pieceRight) {
            this.moveAll(pieceLeft.x + pieceLeft.width, pieceLeft.y);
            pieceLeft.pieceRight = this;
            this.pieceLeft = pieceLeft;
            didSnap = true;
        }
        
        if (pieceRight && pieceRight.puzzleX == this.puzzleX+1 && pieceRight.puzzleY == this.puzzleY && !pieceRight.pieceLeft) {
            this.moveAll(pieceRight.x - this.width, pieceRight.y);
            this.pieceRight = pieceRight;
            pieceRight.pieceLeft = this;
            didSnap = true;
        }
        
        if (pieceUp && pieceUp.puzzleY == this.puzzleY-1 && pieceUp.puzzleX == this.puzzleX && !pieceUp.pieceBottom) {
            this.moveAll(pieceUp.x, pieceUp.y + pieceUp.height);
            pieceUp.pieceBottom = this;
            this.pieceTop = pieceUp;
            didSnap = true;
        }
        
        if (pieceDown && pieceDown.puzzleY == this.puzzleY+1 && pieceDown.puzzleX == this.puzzleX && !pieceDown.pieceTop) {
            this.moveAll(pieceDown.x, pieceDown.y - pieceDown.height);
            pieceDown.pieceTop = this;
            this.pieceBottom = pieceDown;
            didSnap = true;
        }
        
        const connected = this.getConnected();
        checkedRecursive.add(this);
        for (const piece of connected.values()) {
            if (checkedRecursive.has(piece)) continue;
            if (piece.checkNear(checkedRecursive)) {
                didSnap = true;
            }
        }
        
        return didSnap;
        
    }
    
    preRender() {
        
        const canvas = this.pieceCanvas;
        const ctx = this.pieceCtx;
        
        ctx.strokeStyle = '#000000';
        ctx.lineWidth = 1;
        
        ctx.beginPath();
        jigsawPiece(ctx, this.width/2, this.height/2, this.width, this.height, [
            this.puzzleY == 0 ? 0 : (this.puzzle.getPiece(this.puzzleX, this.puzzleY-1).sideBottom ? 2 : 1),
            this.puzzleX == this.puzzle.width-1 ? 0 : (this.sideRight ? 1 : 2),
            this.puzzleY == this.puzzle.height-1 ? 0 : (this.sideBottom ? 1 : 2),
            this.puzzleX == 0 ? 0 : (this.puzzle.getPiece(this.puzzleX-1, this.puzzleY).sideRight ? 2 : 1),
        ])
        
        ctx.save();
        ctx.clip();
        
        ctx.drawImage(
            this.puzzle.image,
            this.width/2 + -this.puzzleX*this.width,
            this.height/2 + -this.puzzleY*this.height,
            this.puzzle.width*this.puzzle.pieceWidth,
            this.puzzle.height*this.puzzle.pieceHeight
        );
        
        ctx.restore();
        
        ctx.stroke();
        
    }
    
    render(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, currentPiece: Piece | null) {
        
        if (currentPiece == this) {
            ctx.filter = 'blur(5px)';
            ctx.beginPath();
            jigsawPiece(ctx, this.x + 3, this.y + 3, this.width, this.height, [
                this.puzzleY == 0 ? 0 : (this.puzzle.getPiece(this.puzzleX, this.puzzleY-1).sideBottom ? 2 : 1),
                this.puzzleX == this.puzzle.width-1 ? 0 : (this.sideRight ? 1 : 2),
                this.puzzleY == this.puzzle.height-1 ? 0 : (this.sideBottom ? 1 : 2),
                this.puzzleX == 0 ? 0 : (this.puzzle.getPiece(this.puzzleX-1, this.puzzleY).sideRight ? 2 : 1),
            ]);
            ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
            ctx.fill();
        }
        
        if (this.isAnimating) {
            
            const animationTime = Date.now() - this.timeStartAnimating;
            const animationPos = Math.max(0, Math.min(1, animationTime/2000));
            
            this.x = this.fromX + (this.targetX-this.fromX)*easeInOutQuad(animationPos);
            this.y = this.fromY + (this.targetY-this.fromY)*easeInOutQuad(animationPos);
            
            if (animationPos >= 1) {
                
                this.x = this.targetX;
                this.y = this.targetY + 10;
                this.isAnimating = false;
                
                const sound = SOUNDS_PLACE[Math.floor(Math.random()*SOUNDS_PLACE.length)];
                const soundId = sound.play();
                sound.rate(0.6 + Math.random()*0.4, soundId)
                sound.volume(0.05, soundId);
                
                if (this.puzzleX == this.puzzle.width-1 && this.puzzleY == this.puzzle.height-1) {
                    setTimeout(() => this.checkNear(), 500);
                }
                
            }
            
        }
        
        ctx.filter = 'none';
        ctx.drawImage(this.pieceCanvas, this.x - this.width/2, this.y - this.height/2);
        
    }
    
}

function easeInOutQuad(x: number): number {
    return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
}
